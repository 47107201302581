import {format} from 'date-fns'
import React from 'react'
import {buildImageObj, isInThePast} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
import '../styles/event-single.css'
import {Link} from 'gatsby'
import ReactPlayer from 'react-player'


function Event (props) {
  const {title, fesivalYear, location, url, slug, _rawDescription, endDate, beginDate, images, links, pdf, youtube} = props

  var datepast = false
  if (typeof window !== "undefined") {
    datepast = isInThePast( new Date(endDate) );
  }

  return (
    <Container>

    <div id="festival-event">
      <div className="wrapper">
        <div id="festival-event-inner">

              <h2>Events</h2>
              <p className="day">{format(new Date(beginDate), 'MMMM d, yyyy')}</p>

          <div className="event-item grid">

            <div className="width-4-12-m width-4-12">
              <p>{format(new Date(beginDate), 'h:mm a')} – {format(new Date(endDate), 'h:mm a')}</p>
              {location && <p>{location}</p>}
              {!datepast && 
                <>{url && url.title && url.url && <p><a className="more" target="_blank" rel="noopener noreferrer" href={url.url}>{url.title}</a></p> }</>
              }
            </div>

            <div className="width-8-12-m width-8-12">
                <p className="event-title">{title}</p>
                <PortableText blocks={_rawDescription} />

                {pdf && 
                  <div className="pdf">
                  {pdf && pdf.file && 
                      <p className="extras"><a href={pdf.file.asset.url} rel="noopener noreferrer" target="_blank">{pdf.title} (pdf)</a></p>
                  }
                  </div>
                } 

                {links &&
                  links.map(link => (
                  <p>
                    <a target="_blank" rel="noreferrer" title={link.title} href={link.url}>
                      {link.title}
                    </a>
                  </p>    
                  ))
                }  

            </div>

          </div>

          <div className="event-content grid">

            <div className="width-12-12-m width-9-12">

              {youtube && youtube.url && (
                <div className="video-holder">
                  <p className="event-title">Event Video</p>
                  <div className='player-wrapper'>
                    <ReactPlayer 
                      className='react-player'
                      url={youtube.url}
                      width='100%'
                      height='100%'
                    />
                  </div>
                </div>
              )}
            
              {images && images[0] &&

                <div className="event-gallery">

                <p className="event-title">Event Photos</p>

                <div className="grid">

                {images && images.map(image => {

                  return image && image.asset && (

                  <div className="gallery-image width-4-12 width-4-12-m">
                  <a data-attribute="SRL" href={imageUrlFor(buildImageObj(image))
                        .width(2000)
                        .url()}
                  >
                    <img
                      src={imageUrlFor(buildImageObj(image))
                        .width(800)
                        .height(800)
                        .fit('crop')
                        .url()}
                      alt={image.alt}
                    />
                    </a>
                  </div>
                )
                })}

                </div>

                </div>

            }

            </div>

          </div>

        </div>

      </div>
    </div>

    </Container>
  )
}

export default Event