import React from 'react'
import {graphql} from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import Container from '../components/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Event from '../components/event'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      metadata {
        dimensions {
          aspectRatio
        }
        lqip
      }
      _id
    }
  }
  query EventQuery($id: String!, $year: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      instagramLink
      facebookLink
      footer
      description
      twitter
      title
      comingSoon
      email
      author
      keywords
    }

    event: sanityEvent(id: {eq: $id}) {
      title
      festivalYear {
        title
      }
      location
      slug {
        current
      }
      _rawDescription
      endDate
      beginDate
      images {
        ...SanityImage
        alt
        caption
      }
      url {
        title
        url
      }
      links {
        title
        url
      }
      pdf {
        title
        file {
          asset {
            url
          }
        }
      }
      youtube {
        url
      }
    }

    festival: sanityYear(title: {eq: $year}) {
      _rawIntroduction
      _rawComingSoon
      _rawCall
      title
      beginDate
      endDate
      horizontalLogo {
        ...SanityImage
        alt
      }
      stackedLogo {
        ...SanityImage
        alt
      }
      siteImage {
        ...SanityImage
        alt
      }
      colorA
      colorB
      colorC
      bodyFont
      secondaryFont
      links {
        title
        url
      }
      partners {
        title
        url
        image {
          ...SanityImage
          alt
        }
      }
    }

  }
`

const EventsPage = props => {
  const {data, errors} = props

  const site = (data || {}).site
  const event = data && data.event
  const festival = (data || {}).festival

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  
    return (

      <Layout 
        title={site.title} 
        email={site.email} 
        footer={site.footer} 
        partners={festival.partners} 
        colorA={festival.colorA} 
        colorB={festival.colorB} 
        colorC={festival.colorC} 
        bodyFont={festival.bodyFont} 
        secondaryFont={festival.secondaryFont} 
        horizontalLogo={festival.horizontalLogo}
        instagramLink={site.instagramLink} 
        facebookLink={site.facebookLink} 
        twitter={site.twitter} 
        linkedInLink={site.linkedInLink} >
        <SEO
          title={site.title}
          author={site.author}
          description={site.description}
          keywords={site.keywords}
          image={festival.siteImage}
        />
        <Container>
          {event && (
            <Event {...event} />
          )}
        </Container>
      </Layout>
    )

}

export default EventsPage